<template>
  <div>
    <vs-popup
      class="holamundo popup-width company-details-modal"
      title="Shopping Cart Details"
      :active.sync="consumerDetailPopup"
    >
      <vs-button
        @click="closepopup"
        color="secondary"
        type="filled"
        class="mb-8 float-right close-popup-btn"
        :title="LabelConstant.buttonLabelClose"
      >
        {{ LabelConstant.buttonLabelClose }}
      </vs-button>
      <div class="abandoned-cart-list" v-if="consumerDetail">
        <vx-card class="mb-base">
          <h4 class="mt-4 mb-5">
            {{ consumerName }}
          </h4>
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-align="start"
              vs-lg="6"
              vs-sm="12"
              class="mb-3"
            >
              <div class="icon-style mr-3">
                <span class="material-icons"> mail_outline </span>
              </div>
              <div class="consumer-text" v-if="consumerDetail.Email">
                <h5>Email Address</h5>
                <p>{{ consumerDetail.Email }}</p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-align="start"
              vs-lg="6"
              class="mb-3"
              vs-sm="12"
            >
              <div class="icon-style mr-3">
                <feather-icon icon="MapPinIcon"></feather-icon>
              </div>
              <div class="consumer-text" v-if="consumerDetail.Physical_Address">
                <h5>Physical Address</h5>
                <p>{{ consumerDetail.Physical_Address }}</p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-align="start"
              vs-lg="6"
              vs-sm="12"
              class="mb-3"
            >
              <div class="icon-style mr-3">
                <feather-icon icon="CalendarIcon"></feather-icon>
              </div>
              <div class="consumer-text" v-if="sessions && sessions.length > 0 && sessions[0].Date_Created">
                <h5>Date / Time</h5>
                <p>{{ sessions[0].Date_Created }}</p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-align="start"
              vs-lg="6"
              class="mb-3"
              vs-sm="12"
            >
              <div class="icon-style mr-3">
                <feather-icon icon="LinkIcon"></feather-icon>
              </div>
              <div class="consumer-text" v-if="sessions && sessions.length > 0 && sessions[0].Campaign_Source">
                <h5>Source and Keywords Used</h5>
                <p>
                  {{ sessions[0].Campaign_Source }} :
                  {{
                    sessions[0].Campaign_Term
                      ? sessions[0].Campaign_Term
                      : "No keyword information found"
                  }}
                </p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-align="start"
              vs-lg="6"
              class="mb-3"
              vs-sm="12"
            >
              <div class="icon-style mr-3">
                <font-awesome-icon icon="venus-mars" />
              </div>
              <div class="consumer-text" v-if="consumerDetail.Gender">
                <h5>Gender</h5>
                <p>{{ consumerDetail.Gender == "M" ? "Male" : "Female" }}</p>
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-align="start"
              vs-lg="6"
              vs-sm="12"
              class="mb-3"
            >
              <div class="icon-style mr-3">
                <span class="material-icons"> portrait </span>
              </div>
              <div class="consumer-text" v-if="consumerDetail.Age">
                <h5>Estimated Age</h5>
                <p>{{ consumerDetail.Age }}</p>
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
        <vx-card class="mb-base">
          <vs-tabs class="mt-0">
            <!-- Shopping Cart Details -->
            <vs-tab label="Shopping Cart">
              <div class="tab-text page-view">
                <div class="tab-title">
                  <h3>Abandoned Shopping Cart - Details</h3>
                </div>
                <vs-table ref="tableData" class="search-cart-detail" :data="consumerDetail.ShoppingCartDetails">
                  <template slot="thead">
                    <vs-th></vs-th>
                    <vs-th>Product</vs-th>
                    <vs-th>Id</vs-th>
                    <vs-th>Price</vs-th>
                    <vs-th>Quantity</vs-th>
                    <vs-th>Subtotal</vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].ImageURL"
                        ><img
                          v-if="data[indextr].ImageURL && data[indextr].ImageURL != ''"
                          width="50"
                          :src="data[indextr].ImageURL"
                          alt="Product-img"
                      />
                      <img
                          v-else
                          width="50"
                          src="../../../assets/images/shopping-cart.png"
                          alt="Product-img"
                      />
                    </vs-td>
                      <vs-td :data="data[indextr].ProductName">{{ data[indextr].ProductName }}</vs-td>
                      <vs-td :data="data[indextr].ProductId">{{ data[indextr].ProductId }}</vs-td>
                      <vs-td :data="data[indextr].ProductPrice">{{ '$'+currencyFormatter(data[indextr].ProductPrice) }}</vs-td>
                      <vs-td :data="data[indextr].Quantitiy">{{ data[indextr].Quantitiy }}</vs-td>
                      <vs-td :data="data[indextr].SubTotal">{{ '$'+currencyFormatter(data[indextr].SubTotal) }}</vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
                <div class="search-cart-total" v-if="consumerDetail && consumerDetail.ShoppingCartDetails">
                  <strong>Cart Total:</strong>
                  <span>{{'$'+currencyFormatter(cartTotal)}}</span>
                </div>
              </div>
            </vs-tab>
            <!-- Shopping History -->
            <vs-tab label="Shopping History">
              <div class="tab-text page-view">
                <div class="tab-title">
                  <h3 id="ProductViewed">Product Viewed - Details</h3>
                </div>
                <div class="cool-timeline-wrapper">
                  <h2 class="timeline-main-title center-block">
                    Buyer Session
                  </h2>
                  <div class="cool-timeline ultimate-style white-timeline">
                    <div class="cooltimeline_cont clearfix icons_no">
                      <div
                        class="timeline-year scrollable-section default-year"
                        id="scrollto-section-tm-default-default-8-navi-2022"
                      >
                        <!-- <span class="ctl-timeline-date">2023</span> -->
                      </div>
                      <div v-for="(product,i) in consumerDetail.ProductsViewed" :key="i"
                        class="timeline-post light-grey-post icons_no post-13621 default-meta"
                        :class="[ !product.IsAdded ? 'even' : 'odd story-cat-248' ]"
                      >
                        <div class="timeline-meta aos-init aos-animate">
                          <div class="meta-details">{{ product.ViewedAt }}</div>
                        </div>
                        <div
                          class="timeline-icon icon-dot-full default-dot aos-init aos-animate"
                        >
                          <div class="timeline-bar"></div>
                        </div>
                        <div
                          class="timeline-content clearfix full default-content aos-init aos-animate"
                          :class="[ !product.IsAdded ? 'even' : 'odd' ]"
                        >
                          <h2 class="content-title">
                            <!-- {{ product.ProductName + ' : $' + currencyFormatter(product.Price)}} -->
                            {{ product.IsAdded ? 'Added to Cart' : 'Viewed' }}
                          </h2>
                          <div class="ctl_info event-description full">
                            <div class="full-width ctl_slideshow">
                              <div class="ctl_flexslider">
                                <div class="ctl_item_img">
                                  <img
                                      class=""
                                      :src="product.ImageURL"
                                  />
                              </div>
                              <div class="ctl_item_detail">
                                  <p class="ctl_item_name">{{ product.ProductName }}</p>
                                  <div class="ctl_item_price">
                                    Price: <span>${{ currencyFormatter(product.Price) }}</span>
                                  </div>
                                  <div class="ctl_item_qty" v-if="product.IsAdded">
                                    Qty added: <span>{{ product.QtyAdded }}</span>
                                  </div>
                                </div>
                            </div>
                            </div>
                            <!-- <div class="content-details">
                              <p>{{  }}</p>
                            </div> -->
                          </div>
                        </div>
                        <!-- timeline content -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </vs-tab>
            <!-- Pages Viewed -->
            <vs-tab label="Pages Viewed">
              <div class="con-tab-ejemplo page-view tab-text">
                <div class="tab-title">
                  <h3 class="py-2">Pages Viewed - Detailed</h3>
                </div>
                <ul>
                    <li v-for="session in consumerDetail.Sessions" :key="session.Session_Id">
                                    <h4>Session: {{ session.Date_Created }}</h4>
                                    <!-- <span>Visitor Id: {{ session.Visitor_Id }}</span> -->
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Pages Viewed</th>
                                                <th width="130px">Time Spent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="pageView in session.Page_Views" :key="pageView.Page_Url">
                                                <td>{{pageView.Page_Url }}</td>
                                                <td width="130px">{{ pageView.Time_Spent }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                </ul>
              </div>
            </vs-tab>
          </vs-tabs>
        </vx-card>
        <div class="text-right">
          <vs-button
            color="grey"
            @click="closepopup"
            type="border"
            class="grey-btn mb-5"
          >
            {{ LabelConstant.buttonLabelClose }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  props: {
    consumerDetailPopup: {
      type: Boolean,
      required: true,
    },
    consumerDetail: {
      required: true,
    },
    consumerLeadId: {
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    consumerDetailData: {
      get() {
        return this.consumerDetail;
      },
    },
    professionalDetails: {
      get() {
        return this.consumerDetail
          ? this.consumerDetail.ProfessionalDetails
          : {};
      },
    },
    sessions: {
      get() {
        return this.consumerDetail.Sessions;
      },
    },
    keywords: {
      get() {
        return this.consumerDetail.Keywords;
      },
    },
    consumerName: {
      get() {
        return this.consumerDetail
          ? this.consumerDetail.First_Name + " " + this.consumerDetail.Last_Name
          : "";
      },
    },
    cartTotal: {
      get() {
        let cartTotal = 0
        for (let i = 0; i < this.consumerDetail.ShoppingCartDetails.length; i++) {
            cartTotal += this.consumerDetail.ShoppingCartDetails[i].SubTotal
        }
        return cartTotal
      },
    },
    address: {
      get() {
        let consumerAddressArr = [];
        consumerAddressArr.push(this.professionalDetails.CompanyAddressLine1);
        consumerAddressArr.push(this.professionalDetails.CompanyAddressLine2);
        consumerAddressArr.push(this.professionalDetails.CompanyCity);
        consumerAddressArr.push(this.professionalDetails.CompanyRegion);
        consumerAddressArr.push(this.professionalDetails.CompanyCountry);
        let companyAddressFinal = "";
        for (let i = 0; i < consumerAddressArr.length; i++) {
          if (consumerAddressArr[i] && consumerAddressArr[i].length > 0) {
            companyAddressFinal += consumerAddressArr[i] + ", ";
          }
        }
        companyAddressFinal = companyAddressFinal.substring(
          0,
          companyAddressFinal.length - 2
        );
        return this.professionalDetails ? companyAddressFinal : "";
      },
    },
  },
  methods: {
    closepopup() {
      this.$emit("close");
    },
    oddEven(value){
        if(value % 2==0){
            return true
        }
        else {
            return false
        }
    }
  },
};
</script>
